import React, { useEffect } from "react";
import TextInput from './text_input'
import DateInput from './date_input'
import SelectInput from './select_input'
import NumberInput from './number_input'

const Form = ({technician, setTechnician, setRefresh}) => {
   
    let genderOptions = [
        {label: "Monsieur", value: "Monsieur"},
        {label: "Madame", value: "Madame"}
    ]

    let sizeOptions = [
        {label: "XXS", value: "XXS"},
        {label: "XS", value: "XS"},
        {label: "S", value: "S"},
        {label: "M", value: "M"},
        {label: "L", value: "L"},
        {label: "XL", value: "XL"},
        {label: "XXL", value: "XXL"},
        {label: "XXXL", value: "XXXL"},
    ]

    let geozoneOptions = [{label:"01", value:"01"},            
                {label:"02", value:"02"},            
                {label:"03", value:"03"},            
                {label:"04", value:"04"},            
                {label:"05", value:"05"},            
                {label:"06", value:"06"},            
                {label:"07", value:"07"},            
                {label:"08", value:"08"},            
                {label:"09", value:"09"},            
                {label:"10", value:"10"},            
                {label:"11", value:"11"},            
                {label:"12", value:"12"},            
                {label:"13", value:"13"},            
                {label:"14", value:"14"},            
                {label:"15", value:"15"},
                {label:"16", value:"16"},
                {label:"17", value:"17"},
                {label:"18", value:"18"},
                {label:"19", value:"19"},
                {label:"20", value:"20"},
                {label:"21", value:"21"},
                {label:"22", value:"22"},
                {label:"23", value:"23"},
                {label:"24", value:"24"},
                {label:"25", value:"25"},
                {label:"26", value:"26"},
                {label:"27", value:"27"},
                {label:"28", value:"28"},
                {label:"29", value:"29"},
                {label:"30", value:"30"},
                {label:"31", value:"31"},
                {label:"32", value:"32"},
                {label:"33", value:"33"},
                {label:"34", value:"34"},
                {label:"35", value:"35"},
                {label:"36", value:"36"},
                {label:"37", value:"37"},
                {label:"38", value:"38"},
                {label:"39", value:"39"},
                {label:"40", value:"40"},
                {label:"41", value:"41"},
                {label:"42", value:"42"},
                {label:"43", value:"43"},
                {label:"44", value:"44"},
                {label:"45", value:"45"},
                {label:"46", value:"46"},
                {label:"47", value:"47"},
                {label:"48", value:"48"},
                {label:"49", value:"49"},
                {label:"50", value:"50"},
                {label:"51", value:"51"},
                {label:"52", value:"52"},
                {label:"53", value:"53"},
                {label:"54", value:"54"},
                {label:"55", value:"55"},
                {label:"56", value:"56"},
                {label:"57", value:"57"},
                {label:"58", value:"58"},
                {label:"59", value:"59"},
                {label:"60", value:"60"},
                {label:"61", value:"61"},
                {label:"62", value:"62"},
                {label:"63", value:"63"},
                {label:"64", value:"64"},
                {label:"65", value:"65"},
                {label:"66", value:"66"},
                {label:"67", value:"67"},
                {label:"68", value:"68"},
                {label:"69", value:"69"},
                {label:"70", value:"70"},
                {label:"71", value:"71"},
                {label:"72", value:"72"},
                {label:"73", value:"73"},
                {label:"74", value:"74"},
                {label:"75", value:"75"},
                {label:"76", value:"76"},
                {label:"77", value:"77"},
                {label:"78", value:"78"},
                {label:"79", value:"79"},
                {label:"80", value:"80"},
                {label:"81", value:"81"},
                {label:"82", value:"82"},
                {label:"83", value:"83"},
                {label:"84", value:"84"},
                {label:"85", value:"85"},
                {label:"86", value:"86"},
                {label:"87", value:"87"},
                {label:"88", value:"88"},
                {label:"89", value:"89"},
                {label:"90", value:"90"},
                {label:"91", value:"91"},
                {label:"92", value:"92"},
                {label:"93", value:"93"},
                {label:"94", value:"94"},
                {label:"95", value:"95"}
        ]

    return (
        <div className="form profile-form">
            
            <div className="form-part">
                <h3>Général</h3>
                <div className="line">
                    <SelectInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="gender" title="Civilité" options={genderOptions} />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="last_name" title="Nom" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="first_name" title="Prénom" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="num_conge_spectacle" title="Numéro congé spectacle" />
                </div>
            </div>

            <div className="form-part">
                <h3>Données administratives</h3>
                <div className="line">
                    <DateInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="subscribe_at" title="Date d'entrée SdF" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="security_number" title="Numéro de Sécu" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="profession" title="Activité professionnelle" />
                    <SelectInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="cloth_size" title="Taille vestimentaire" options={sizeOptions} />
                </div>
                <div className="line">
                    <DateInput editable={false} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="birth_date" title="Date de naissance" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="birth_city" title="Lieu de naissance" />
                    <SelectInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="birth_geozone" title="Département de naissance" options={geozoneOptions} />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="birth_country" title="Pays de naissance" />
                </div>
                <div className="line">
                    {/* to do : format rendering  */}
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="urgency_contact" title="Personnes à contacter en cas de problème" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="urgency_contact_phone" title="Téléphone de la personne à contacter en cas de problème" />
                </div>
                <div className="line">
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="address" title="Adresse" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="address_bis" title="Adresse (bis)" />
                    <SelectInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="geozone" title="Département" options={geozoneOptions} />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="zip_code" title="Code postal" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="city" title="Ville" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="country" title="Pays" />
                </div>
                <div className="line">
                    <NumberInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="personal_phone" title="Tel. fixe" pattern="phone" />
                    <NumberInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="mobile_phone" title="Tel. portable" pattern="phone" />
                    <NumberInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="office_phone" title="Tel. pro" pattern="phone" />
                    <TextInput editable={true} object={technician} setObject={setTechnician} setRefresh={setRefresh} slug="email" title="Email" />
                </div>
            </div>

        </div>
    )

};

export default Form;