import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from './navbar'
import DataTable from './datatable';
import DocumentModal from './document_modal';

const SharedDocuments = () => {
   
    return (
        <div className="main">
            <Navbar />
            <div className="documents work-space">
                <div className="header">
                </div>
                <div className="body">
                    <div className="page-title">
                      <h2>Documents communs SdF</h2>
                    </div>
                    <div className="document-bloc">
                        <div className="title">Notices d’utilisation des systèmes</div>
                        <a target="_blank" href="/fiches_utilisations/FU-001-Cléolia v2.pdf">FU-001-Cléolia v2</a>
                        <a target="_blank" href="/fiches_utilisations/FU-002-MAF 60 et télécommande filaire v2.pdf">FU-002-MAF 60 et télécommande filaire v2</a>
                        <a target="_blank" href="/fiches_utilisations/FU-004-Oxydium avec MAF60.pdf">FU-004-Oxydium avec MAF60</a>
                        <a target="_blank" href="/fiches_utilisations/FU-005-Sparkular.pdf">FU-005-Sparkular</a>
                      </div>
                      <div className="document-bloc">
                        <div className="title">Fiches référentielles</div>
                        <a target="_blank" href="/fiches_referentielles/FR-001-Distance de sécurité et implantation des produits.pdf">FR-001-Distance de sécurité et implantation des produits</a>
                        <a target="_blank" href="/fiches_referentielles/FR-002-Préparation et fixation des produits.pdf">FR-002-Préparation et fixation des produits</a>
                        <a target="_blank" href="/fiches_referentielles/FR-003-Check list du chef de tir.pdf">FR-003-Check list du chef de tir</a>
                        <a target="_blank" href="/fiches_referentielles/FR-004-Consignes de sécurité.pdf">FR-004-Consignes de sécurité</a>
                        <a target="_blank" href="/fiches_referentielles/FR-005-Les essentiels de l'artificier - V2 - 06-2022.pdf">FR-005-Les essentiels de l'artificier - V2 - 06-2022</a>
                      </div>
                      <div className="document-bloc">
                        <div className="title">Notes de services et consignes particulières</div>
                        <a target="_blank" href="/notes_service/Note service-9-cadre d'emploi-v1.pdf">Note service-9-cadre d'emploi-v1</a>
                        <a target="_blank" href="/notes_service/Note service-10-congés spectacles-v1.pdf">Note service-10-congés spectacles-v1</a>
                        <a target="_blank" href="/notes_service/Note service-11-signature électronique-v1.pdf">Note service-11-signature électronique-v1</a>
                        <a target="_blank" href="/notes_service/Note service-12-Transat-v1.pdf">Note service-12-Transat-v1</a>
                      </div>
                      <div className="document-bloc">
                        <div className="title">Newsletters</div>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°1 janvier 2021.pdf">Au fil de l'artifice n°1 janvier 2021</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°2 avril 2021.pdf">Au fil de l'artifice n°2 avril 2021</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°3 septembre 2021.pdf">Au fil de l'artifice n°3 septembre 2021</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°4 janvier 2022.pdf">Au fil de l'artifice n°4 janvier 2022</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°5 mai 2022.pdf">Au fil de l'artifice n°5 mai 2022</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°6 octobre 2022.pdf">Au fil de l'artifice n°6 octobre 2022</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°7 janvier 2023.pdf">Au fil de l'artifice n°7 janvier 2023</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°8 mai-août 2023.pdf">Au fil de l'artifice n°8 mai-août 2023</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice n°9 octobre 2023.pdf">Au fil de l'artifice n°9 octobre 2023</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice - 2024-04 #10.pdf">Au fil de l'artifice n°10 avril 2024</a>
                        <a target="_blank" href="/newsletters/Au fil de l'artifice - 2024-10 #11.pdf">Au fil de l'artifice n°11 octobre 2024</a>
                      </div>
                </div>
            </div>
        </div>
    )

};

export default SharedDocuments;
